import React from 'react';
import FirstScreen from './FirstScreen.jsx';
import SecondSection from './SecondSection.jsx';
import Benefits from './Benefits.jsx';
import Cards from './Cards.jsx';
import Testimonials from './Testimonials.jsx';
import BannerDemo from './BannerDemo.jsx';
import FAQ from './FAQ.jsx';
import BannerDemoTwo from './BannerDemoTwo.jsx';

const Principal = () => {
  return (
    <div>
      <FirstScreen />
      <SecondSection />
      <Benefits />
      <Cards />
      <Testimonials />
      <BannerDemo />
      <FAQ />
      <BannerDemoTwo />
    </div>
  );
};

export default Principal;
