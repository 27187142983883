import React from 'react';
import './Lanzamiento.css';

const BannerBotones = () => {
  return (
    < section id="contact">
    <div className='container-bannerbotones'>
      <div className='text-bannerbotones'>
        <h2>DA EL PRIMER PASO HACIA LA AUTOMATIZACIÓN Y DIGITALIZACIÓN</h2>
        <h3>¡ENCUENTRA EL PLAN PERFECTO PARA TI!</h3>
      </div>

      <div className='buttons-bannerbotones'>
        <button className='button-demo-bannerbotones'
          onClick={() => window.location.href = 'https://forms.gle/YE7GCxU5n6WwVt6D6'}>
          WAITING LIST
        </button>

        <button className='button-talk-bannerbotones' 
          onClick={() => window.location.href = 'https://wa.me/528131391075' }>
          MÁS INFORMACIÓN
        </button>

      </div>
    </div>
    </section>
  );
};

export default BannerBotones;