import React from 'react';
import PantallaPrincipal from './PantallaPrincipal.jsx';
import BannerBotones from './BannerBotones.jsx';
import BannerFoto from './BannerFoto.jsx';
import BannerSeguridad from './BannerSeguridad.jsx';
import Cuadros from './Cuadros.jsx';

const Lanzamiento = () => {
  return (
    <div>
      <PantallaPrincipal />
      <Cuadros /> 
      <BannerSeguridad />
      <BannerFoto />
      <BannerBotones />
    </div>
  );
};

export default Lanzamiento;