import React from 'react';
import './Lanzamiento.css';

const BannerFoto = () => {
  return (
    <div className="container-seguridad">
      <div className="image-section-seguridad">
        <img src="/CandadoSoftpital.png" alt="candado" />
      </div>

      <div className="text-seguridad">
        <h1>TODA TU INFORMACIÓN SEGURA EN SOFTPITAL</h1>
        <p>¡No dejes tu información a la deriva! En Softpital, tu seguridad es lo primero. ¡Confía en nosotros y mantén tus datos a salvo!</p>
      </div>
    </div>
  );
};

export default BannerFoto;