import React from 'react';
import './Lanzamiento.css';

const BannerFoto = () => {
  return (
    <div className="container-banner">
      <div className="text-banner">
        <h1>TRANSFORMA DATOS EN DECISIONES ESTRATÉGICAS</h1>
        <p>Da a tu información un valor excepcional</p>

        <button className="blinking-button-first"
          onClick={() => window.location.href = 'https://forms.gle/NQGAmT7aCHHi9unj8'}>
          SE PARTE DE LA WAITING LIST</button>
      </div>

      <div className="image-section-banner">
        <img src="/Excel-Softpital.png" alt="Tablero con excel" />
      </div>
    </div>
  );
};

export default BannerFoto;