import React from 'react';
import './Lanzamiento.css'; 

const PantallaPrincipal = () => {
  return (
    <div className="pantalla-principal">
      <div className="text-principal">
        <h1>EL FUTURO DE LA GESTIÓN DE EQUIPOS Y TECNOLOGÍA BIOMÉDICA</h1>
        <p>Lleva a otro nivel la ingeniería clínica de tu empresa proveedora de equipos médicos o institución de salud (hospital, clínica, consultorio)</p>
        
        <div className="button-lista">
           <button
            className="blinking-button-lista"
            onClick={() => window.location.href = 'https://forms.gle/YE7GCxU5n6WwVt6D6'}>
            SE PARTE DE LA WAITING LIST
           </button>
        </div>   
      
      <div className="conoce-softpital">
         <img src= "/Conoce-Softpital.gif" alt= "GIF Dashboard" /> 
      </div>

      <div className="simplicity">
          <h2>REDISEÑAMOS LA COMPLEJIDAD PARA DARTE SIMPLICIDAD</h2>
          <p>Las herramientas tecnológicas deben ser fáciles de usar para todos, y con Softpital, lo hacemos posible.</p>
        </div>

      </div>
     </div>
  );
};

export default PantallaPrincipal;