import React from 'react';
import './Lanzamiento'; 

function Cuadros() {
  return (
    <section id="cuadros">
    <div className="cuadros-beneficios">

      {/* CUADRO TRANSFORMA */}
      <div className="cuadro">
        <h2> TRANSFORMA </h2>
        <p> En Softpital, somos protagonistas de la transformación digital, abordando los desafíos y problemas que enfrentan las organizaciones en la gestión de activos médicos.  </p>
        <div className="image-transforma">
           <img src="/Excel-Softpital.png" alt="excel" />
        </div>
      </div>
      
      {/* CUADRO AUTOMATIZA Y CONTROLA */}
      <div className="cuadro">
        <h2> AUTOMATIZA Y CONTROLA </h2>
        <p> Organiza y programa todos los servicios de mantenimiento de tus equipos médicos. Usa recordatorios automáticos evitando futuras fallas, y con ello alarga la vida util de tus activos médico. </p>
        <div className="image-automatiza">
           <img src="/Calendario.png" alt="calendario" />
        </div>
      </div>
    
      {/* CUADRO DIGITALIZA Y PERSONALIZA */}
      <div className="cuadro">
        <h2> DIGITALIZA Y PERSONALIZA </h2>
        <p> Ten acceso rápido a toda tu información desde cualquier lugar que garantice mayor eficiencia y agilidad. Da seguimiento a todos los servicios y crea cualquier los documento que necesites 100% personalisables. </p>
        <div className="image-digitaliza">
           <img src="/Poliza.png" alt="poliza" />
        </div>
      </div>

      {/* CUADRO ANALIZA Y PLANIFICA */}
      <div className="cuadro">
        <h2> ANALIZA Y PLANIFICA </h2>
        <p> Con estadística en tiempo real conoce el retorno de inversión de tus equipos así como su vida útil para una toma de desiciones informadas.  </p>
        <div className="image-analiza">
           <img src="/Tablero1.png" alt="tablero" />
        </div>
      </div>

    </div>
    </section>
  );
}

export default Cuadros;
