import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Principal.css';

function Testimonials() {
  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <section className="testimonials">
      <div className='header-testimonials'>
        <h1>Citas que resuenan</h1>
        <h5>IMPORTANCIA DE UN CMMS PARA GESTIONAR TUS EQUIPOS Y TÉCNOLOGÍA MÉDICA</h5>
      </div>

      <Slider {...settings}>
        <div className="testimonial">
          <p>"One of the core disciplines in clinical engineering is the management of healthcare technologies, involving assess-ment, evaluation, procurement, control, asset management, maintenance and repair, replacement planning, and more tasks".</p>
          <p><strong>Clinical Engineering Handbook, 2020, p.xlv </strong></p>
        </div>
        <div className="testimonial">
          <p>"Toda organización que pretenda contar con un programa eficaz de gestión de equipo médico deberá disponer de un inventario de equipos y mantenerlo".</p>
          <p><strong>Introducción a la gestión de inventarios de equipo médico, 2012, p.21-22</strong></p>
        </div>
        <div className="testimonial">
          <p>"Un inventario sólo es eficaz si es exhaustivo y exacto. Para ello debe actualizarse siempre que haya cambios o información nueva, así como en las auditorías y exámenes anuales".</p>
          <p><strong>Introducción a la gestión de inventarios de equipo médico, 2012, p.18</strong></p>
        </div>
        <div className="testimonial">
          <p>"Aunque es perfectamente aceptable registrar un inventario en papel, un sistema computarizados puede facilitar su gestión, especialmente si es grande".</p>
          <p><strong>Introducción a la gestión de inventarios de equipo médico, 2012, p.19</strong></p>
        </div>
      </Slider>
    </section>
  );
}

export default Testimonials;
