import React from 'react';
import './Principal.css';

const BannerDemo = () => {
  return (
    <div className="container-demo">
      <div className="text-demo">
        <h1>NO ESPERES MÁS PARA DAR EL PRIMER PASO Y PONER A PRUEBA TODAS LAS FUNCIONALIDADES</h1>
        <p>Tu aliado más fuerte para optimizar procesos y transformar la gestión de tecnología médica</p>
        <button className="blinking-button-first"
          onClick={() => window.location.href = 'https://calendly.com/contacto-softpital/junta-softpital'}>
          AGENDA UNA DEMO</button>
      </div>
      <div className="image-section-demo">
        <img src="/tableroprincipal.png" alt="tableroprincipal" />
      </div>
    </div>
  );
};

export default BannerDemo;