import React from 'react';
import './Principal.css';

const BannerDemoTwo = () => {
  return (
    < section id="contact">
    <div className='container-bannertwo'>
      <div className='text-bannertwo'>
        <h2>DA EL PRIMER PASO HACIA LA AUTOMATIZACIÓN Y DIGITALIZACIÓN</h2>
        <h3>¡ENCUENTRA EL PLAN PERFECTO PARA TI!</h3>
      </div>
      <div className='buttons-bannertwo'>
        <button className='button-demo-bannertwo'
          onClick={() => window.location.href = 'https://calendly.com/contacto-softpital/junta-softpital'}>
          AGENDA UNA DEMO
        </button>
        <button className='button-talk-bannertwo' 
          onClick={() => window.location.href = 'https://wa.me/528131391075' }>
          CHATEA CON UN EXPERTO
        </button>
      </div>
    </div>
    </section>
  );
};

export default BannerDemoTwo;