import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './componentsPrincipal/Nav.jsx';
import Footer from './componentsPrincipal/Footer.jsx';
import Principal from './componentsPrincipal/Principal.jsx';
import Lanzamiento from './componentsLanzamiento/Lanzamiento.jsx';
import { useEffect } from "react";

function App() {

  useEffect(() => {
    // Cambia el título de la pestaña
    document.title = "Softpital";
    
    const changeFavicon = (linkURL) => {
      const existingLinks = document.querySelectorAll('link[rel="icon"]');

      existingLinks.forEach((link) => {
        document.head.removeChild(link);
      });

      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = linkURL;
      document.head.appendChild(newLink);
    };

    // Llama a la función con la ruta de tu nuevo ícono
    changeFavicon(`${process.env.PUBLIC_URL}/logo.png`);
  });

  return (
    <Router>
      
      <Nav /> {/* Siempre aparecerá porque esta fuera del router */}
      
      <Routes>
        <Route path="/" element={<Principal />} /> 
        <Route path="/lanzamiento" element={<Lanzamiento />} />
      </Routes>
      <Footer /> {/* Siempre aparecerá porque esta fuera del router */}

    </Router>
  );
}

export default App;
