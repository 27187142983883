import React, { useEffect, useRef } from 'react';
import './Principal.css';

const Benefits = () => {
  const sectionRef1 = useRef(null);
  const sectionRef2 = useRef(null);
  const sectionRef3 = useRef(null);

  useEffect(() => {
    const options = { threshold: 0.1 };

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        const img = entry.target.querySelector('img');
        if (entry.isIntersecting) {
          img.classList.add('animate');
        } else {
          img.classList.remove('animate');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    
    const sections = [sectionRef1, sectionRef2, sectionRef3];
    sections.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="container-benefits">
      <h1>DESCUBRE EL PODER DE LO SIMPLE CON CALIDAD SOFTPITAL EXCEPCIONAL</h1>

      <div className="section-benefits3" ref={sectionRef1}>
        <div className="sliding-image3">
          <img src="/Computadora2.png" alt="Computadora2" />
        </div>
        <div className="text-benefits">
          <h4>Facilita la toma de decisiones estratégicas, controlando el retorno de inversión de tus equipos y prolongando su vida útil</h4>
          <p>Obtén una comprensión clara de cada situación con nuestros reportes y análisis detallados. Toma decisiones estratégicas que generen mayor impacto</p>
        </div>
      </div>

      <div className="section-benefits2" ref={sectionRef2}>
        <div className="text-benefits2">
          <h2>Automatiza la planificación y gestión de los servicios y mantenimientos de tus equipos médicos</h2>
          <p>Minimiza el tiempo dedicado a tareas repetitivas, permitiendo un enfoque más estratégico en las decisiones clave</p>
        </div>
        <div className="sliding-image2">
          <img src="/Tablet.png" alt="Tablet" />
        </div>
      </div>

      <div className="section-benefits" ref={sectionRef3}>
        <div className="sliding-image">
          <img src="Celular.png" alt="Celular" />
        </div>
        <div className="text-benefits">
          <h4>Accede de manera rápida y sencilla al historial de tus equipos médicos desde cualquier dispositivo</h4>
          <p>Cuenta con acceso a la información desde cualquier lugar, lo que te permitirá brindar una atención más eficiente y dinámica</p>
        </div>
      </div>
      
      <div className="demo-button-benefits">
        <button
          className="demo-benefits"
          onClick={() => window.location.href = 'https://calendly.com/contacto-softpital/junta-softpital'}>
          PROBAR AHORA
        </button>
      </div>
    </div>
  );
};

export default Benefits;
